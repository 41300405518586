///////////////////////(((((((((( nav bar ))))))))))
const menu = document.querySelector("#menu-bar");
const nav = document.querySelector("#nav-list");

menu.onclick = () => {
  nav.classList.toggle("navb-collapse");
};
////////////////// hero section ((( slider )))
if (document.querySelector(".mySwiper")) {
  var swiper = new Swiper(".mySwiper", {
    cssMode: true,
    loop: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    autoplay: { delay: 4000, disableOnInteraction: false },
    mousewheel: true,
    keyboard: true,
  });
}

///////////////////////////////////////////////// gallery
if (document.querySelector(".gallery")) {
  let swiper = new Swiper(".gallery-slider", {
    effect: "coverflow",
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: "auto",
    coverflowEffect: {
      rotate: 0,
      stretch: 0,
      depth: 100,
      modifier: 2,
      slideShadows: true,
    },
    loop: true,
    autoplay: { delay: 5000, disableOnInteraction: false },
  });
}
////////////////////////////////(((( credibility ))))
if (document.querySelector(".certificates")) {
  var swiper = new Swiper(".certificates-mySwiper", {
    grabCursor: true,
    centeredSlides: true,
    loop: true,

    pagination: {
      el: ".swiper-pagination",
      dynamicBullets: true,
      clickable: true,
    },
    autoplay: { delay: 4000, disableOnInteraction: false },
  });
}

//////////////////////////(((((((( page 2 (portfolio) ))))))))
if (document.querySelector(".portfolio")) {
  const sortBtn = document.querySelector(".filter-menu").children;
  // console.log(sortBtn);
  const sortItem = document.querySelector(".filter-items").children;
  const filterPopup = document.querySelector(".filter-popup");
  const popupBtn = document.querySelector(".filter-popup span");
  for (let i = 0; i < sortBtn.length; i++) {
    sortBtn[i].addEventListener("click", function () {
      // console.log(sortBtn[i]);
      for (let j = 0; j < sortBtn.length; j++) {
        sortBtn[j].classList.remove("current");
      }
      this.classList.add("current");

      const targetData = this.getAttribute("data-target");

      for (let k = 0; k < sortItem.length; k++) {
        sortItem[k].classList.add("delete");
        sortItem[k].classList.remove("active");

        if (
          sortItem[k].getAttribute("data-item") == targetData ||
          targetData == "all"
        ) {
          sortItem[k].classList.remove("delete");
          sortItem[k].classList.add("active");
        }
      }
    });
  }
  document.querySelectorAll(".product-card").forEach((card) => {
    card.onclick = () => {
      filterPopup.style.display = "block";
      document.querySelector(".filter-popup img").src =
        card.children[0].getAttribute("src");
      let contact = card.children[3].innerHTML;
      // console.log(contact);
      document.querySelector(".filter-popup__dec ").innerHTML = contact;
    };
  });
  window.onclick = function (e) {
    if (e.target === filterPopup || e.target === popupBtn) {
      filterPopup.style.display = "none";
    }
  };
}
